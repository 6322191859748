import React, { useEffect, useState } from 'react'
import GradientBox from '../../components/GradientBox'
import PageBackground from '../../components/Layouts/PageBackground'

const TermsPage = () => {
    const [mobileView, setmobileView] = useState(false)

    useEffect(() => {
        if (window.innerWidth <= 600) {
            setmobileView(true);
        }
    }, [])

    return (
        <PageBackground title={'Terms of Service'}>
            {/* <h2 className='page-title gradient-box-title'>B2B Solutions</h2> */}
            {!mobileView ? <GradientBox className={'b2b-uoter-box'}>
                <div className='gradient-box-text-holder'>
                    <div className='privacy-scroll-box'>
                        <p>
                            Terms of Use (&quot;Terms&quot;)<br />
                            Last updated: Feb 1st, 2022<br />
                            <br />
                            Please read these Terms of Use (&quot;Terms&quot;, &quot;Terms of Use&quot;) carefully before using the Social Poker (&ldquo;SoPo&rdquo;) mobile application. Odysseus Software Company, (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates the SoPo mobile application, website, social media pages and related services (collectively, the &quot;Service&quot;).<br />
                            <br />
                            Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service. If you disagree with any part of the terms, then you may not access the Service.<br />
                            <br />
                            This software is for entertainment only. Using this software to gamble for money or anything of value is done so at your own risk. By registering for an Account or otherwise using the Service, you affirm that you (1) are 18 years or older, or 13 years or older and have your parent or guardian's consent to the Agreements, and (2) have the power to enter a binding contract with us and not be barred from doing so under any applicable laws. <br />
                            <br />
                            Purchases<br />
                            If you wish to purchase any product or service made available through the Service (&quot;Purchase&quot;), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.<br />
                            <br />
                            You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct, and complete.<br />
                            By submitting such information, you grant us the right to provide the information to third parties for purposes of facilitating the completion of Purchases.<br />
                            <br />
                            We reserve the right to refuse or cancel your order at any time for certain reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order, or other reasons.<br />
                            <br />
                            We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.<br />
                            <br />
                            <br />
                            <br />
                            Availability, Errors, and Inaccuracies<br />
                            We are constantly updating our offerings of products and services on the Service. The products or services available on our Service may be mispriced, described inaccurately, or unavailable, and we may experience delays in updating information on the Service and in our advertising on other websites.<br />
                            We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.<br />
                            <br />
                            Contests and Promotions<br />
                            Any contests or other promotions (collectively, &quot;Promotions&quot;) made available through the Service may be governed by rules that are separate from these Terms. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.<br />
                            <br />
                            Price Changes<br />
                            Odysseus Software Company, in its sole discretion and at any time, may modify the purchase price for the in-store items. Any in-store item price changes will become effective at the end of the then-current purchase.<br />
                            <br />
                            Refunds<br />
                            As with most downloadable software products, items bought in our games (in-app purchases) are non-refundable. In rare cases, exceptions can be applied.<br />
                            <br />
                            If the purchase was made on an Apple (iOS) device:<br />
                            For purchases made on an iOS device, Odysseus Software Company is not able to handle refunds directly. Please visit Apple support and select the &quot;Contact iTunes store support&quot; link, then &quot;Purchases, billing and redemption&quot; and choose the option that fits you best.<br />
                            <br />
                            If the purchase was made on a Google (Android) device:<br />
                            For purchases made on a Google device Odysseus Software Company is not able to handle refunds directly. Please visit Google Play Help and select &ldquo;Get a refund on Google Play&rdquo; underneath &ldquo;Refunds &amp; returns&rdquo; drop-down and choose the option that fits you best. It may help to have your transaction ID ready. Receipts for transactions have been emailed to you by Google after each purchase.<br />
                            <br />
                            Content<br />
                            Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material (&quot;Content&quot;). You are responsible for the Content that you post to the Service, including its legality, reliability, and appropriateness.<br />
                            <br />
                            By posting Content to the Service, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of your rights to any Content you submit, post or display on or through the Service and you are responsible for protecting those rights. You agree that this license includes the right for us to make your Content available to other users of the Service, who may also use your Content subject to these Terms.<br />
                            <br />
                            You represent and warrant that: (i) the Content is yours or you have the right to use it and grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.<br />
                            <br />
                            Accounts<br />
                            When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.<br />
                            <br />
                            You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.<br />
                            <br />
                            You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.<br />
                            <br />
                            You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar, or obscene.<br />
                            <br />
                            Copyright Policy<br />
                            We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on the Service infringes the copyright or other intellectual property infringement (&quot;Infringement&quot;) of any person.<br />
                            If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Service, you must submit your notice in writing to the attention of &quot;Copyright Infringement&quot; at support@playsopo.com and include in your notice a detailed description of the alleged Infringement.<br />
                            <br />
                            You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is infringing your copyright.<br />
                            <br />
                            Intellectual Property<br />
                            The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Odysseus Software Company and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks may not be used in connection with any product or service without the prior written consent of Odysseus Software Company.<br />
                            <br />
                            Links To Other Websites<br />
                            Our Service may contain links to third-party websites or services that are not owned or controlled by Odysseus Software Company.<br />
                            <br />
                            Odysseus Software Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that Odysseus Software Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services.<br />
                            <br />
                            We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.<br />
                            <br />
                            Termination<br />
                            We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.<br />
                            <br />
                            Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.<br />
                            <br />
                            Limitation Of Liability<br />
                            In no event shall Odysseus Software Company nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.<br />
                            <br />
                            Disclaimer<br />
                            Your use of the Service is at your sole risk. The Service is provided on an &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.<br />
                            <br />
                            Odysseus Software Company, its subsidiaries, affiliates, and its licensors do not warrant that (a) the Service will function uninterrupted, secure or available at any particular time or location; (b) any errors or defects will be corrected; (c) the Service is free of viruses or other harmful components; or (d) the results of using the Service will meet your requirements.<br />
                            <br />
                            Governing Law<br />
                            These Terms shall be governed and construed in accordance with the laws of the United States, without regard to its conflict of law provisions.<br />
                            <br />
                            Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have between us regarding the Service.<br />
                            <br />
                            Changes<br />
                            We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 day&rsquo;s notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.<br />
                            <br />
                            By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.<br />
                            <br />
                            <br />
                            <br />
                            Contact Us<br />
                            If you have any questions about these Terms, please contact us at support@playsopo.com. <br />
                        </p>
                    </div>
                </div>
            </GradientBox>
                : 
                <GradientBox className={'b2b-uoter-box'}>
                <div className='gradient-box-text-holder'>
                    <div className='privacy-mobile-scroll-box'>
                        <p>
                            Terms of Use (&quot;Terms&quot;)<br />
                            Last updated: Feb 1st, 2022<br />
                            <br />
                            Please read these Terms of Use (&quot;Terms&quot;, &quot;Terms of Use&quot;) carefully before using the Social Poker (&ldquo;SoPo&rdquo;) mobile application. Odysseus Software Company, (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates the SoPo mobile application, website, social media pages and related services (collectively, the &quot;Service&quot;).<br />
                            <br />
                            Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service. If you disagree with any part of the terms, then you may not access the Service.<br />
                            <br />
                            This software is for entertainment only. Using this software to gamble for money or anything of value is done so at your own risk. By registering for an Account or otherwise using the Service, you affirm that you (1) are 18 years or older, or 13 years or older and have your parent or guardian's consent to the Agreements, and (2) have the power to enter a binding contract with us and not be barred from doing so under any applicable laws. <br />
                            <br />
                            Purchases<br />
                            If you wish to purchase any product or service made available through the Service (&quot;Purchase&quot;), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.<br />
                            <br />
                            You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct, and complete.<br />
                            By submitting such information, you grant us the right to provide the information to third parties for purposes of facilitating the completion of Purchases.<br />
                            <br />
                            We reserve the right to refuse or cancel your order at any time for certain reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order, or other reasons.<br />
                            <br />
                            We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.<br />
                            <br />
                            <br />
                            <br />
                            Availability, Errors, and Inaccuracies<br />
                            We are constantly updating our offerings of products and services on the Service. The products or services available on our Service may be mispriced, described inaccurately, or unavailable, and we may experience delays in updating information on the Service and in our advertising on other websites.<br />
                            We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.<br />
                            <br />
                            Contests and Promotions<br />
                            Any contests or other promotions (collectively, &quot;Promotions&quot;) made available through the Service may be governed by rules that are separate from these Terms. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.<br />
                            <br />
                            Price Changes<br />
                            Odysseus Software Company, in its sole discretion and at any time, may modify the purchase price for the in-store items. Any in-store item price changes will become effective at the end of the then-current purchase.<br />
                            <br />
                            Refunds<br />
                            As with most downloadable software products, items bought in our games (in-app purchases) are non-refundable. In rare cases, exceptions can be applied.<br />
                            <br />
                            If the purchase was made on an Apple (iOS) device:<br />
                            For purchases made on an iOS device, Odysseus Software Company is not able to handle refunds directly. Please visit Apple support and select the &quot;Contact iTunes store support&quot; link, then &quot;Purchases, billing and redemption&quot; and choose the option that fits you best.<br />
                            <br />
                            If the purchase was made on a Google (Android) device:<br />
                            For purchases made on a Google device Odysseus Software Company is not able to handle refunds directly. Please visit Google Play Help and select &ldquo;Get a refund on Google Play&rdquo; underneath &ldquo;Refunds &amp; returns&rdquo; drop-down and choose the option that fits you best. It may help to have your transaction ID ready. Receipts for transactions have been emailed to you by Google after each purchase.<br />
                            <br />
                            Content<br />
                            Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material (&quot;Content&quot;). You are responsible for the Content that you post to the Service, including its legality, reliability, and appropriateness.<br />
                            <br />
                            By posting Content to the Service, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of your rights to any Content you submit, post or display on or through the Service and you are responsible for protecting those rights. You agree that this license includes the right for us to make your Content available to other users of the Service, who may also use your Content subject to these Terms.<br />
                            <br />
                            You represent and warrant that: (i) the Content is yours or you have the right to use it and grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.<br />
                            <br />
                            Accounts<br />
                            When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.<br />
                            <br />
                            You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.<br />
                            <br />
                            You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.<br />
                            <br />
                            You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar, or obscene.<br />
                            <br />
                            Copyright Policy<br />
                            We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on the Service infringes the copyright or other intellectual property infringement (&quot;Infringement&quot;) of any person.<br />
                            If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Service, you must submit your notice in writing to the attention of &quot;Copyright Infringement&quot; at support@playsopo.com and include in your notice a detailed description of the alleged Infringement.<br />
                            <br />
                            You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is infringing your copyright.<br />
                            <br />
                            Intellectual Property<br />
                            The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Odysseus Software Company and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks may not be used in connection with any product or service without the prior written consent of Odysseus Software Company.<br />
                            <br />
                            Links To Other Websites<br />
                            Our Service may contain links to third-party websites or services that are not owned or controlled by Odysseus Software Company.<br />
                            <br />
                            Odysseus Software Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that Odysseus Software Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services.<br />
                            <br />
                            We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.<br />
                            <br />
                            Termination<br />
                            We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.<br />
                            <br />
                            Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.<br />
                            <br />
                            Limitation Of Liability<br />
                            In no event shall Odysseus Software Company nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.<br />
                            <br />
                            Disclaimer<br />
                            Your use of the Service is at your sole risk. The Service is provided on an &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.<br />
                            <br />
                            Odysseus Software Company, its subsidiaries, affiliates, and its licensors do not warrant that (a) the Service will function uninterrupted, secure or available at any particular time or location; (b) any errors or defects will be corrected; (c) the Service is free of viruses or other harmful components; or (d) the results of using the Service will meet your requirements.<br />
                            <br />
                            Governing Law<br />
                            These Terms shall be governed and construed in accordance with the laws of the United States, without regard to its conflict of law provisions.<br />
                            <br />
                            Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have between us regarding the Service.<br />
                            <br />
                            Changes<br />
                            We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 day&rsquo;s notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.<br />
                            <br />
                            By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.<br />
                            <br />
                            <br />
                            <br />
                            Contact Us<br />
                            If you have any questions about these Terms, please contact us at support@playsopo.com. <br />
                        </p>
                    </div>
                </div>
                </GradientBox>
            }
        </PageBackground >
    )
}

export default TermsPage
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "./Carousel.css"
import one from "../assets/images/howtoplay/carousel/01.png"
import two from "../assets/images/howtoplay/carousel/02.png"
import three from "../assets/images/howtoplay/carousel/03.png"
import four from "../assets/images/howtoplay/carousel/04.png"
import five from "../assets/images/howtoplay/carousel/05.png"
import six from "../assets/images/howtoplay/carousel/06.png"
import seven from "../assets/images/howtoplay/carousel/07.png"
import eight from "../assets/images/howtoplay/carousel/08.png"
import ten from "../assets/images/howtoplay/carousel/09.png"
import nine from "../assets/images/howtoplay/carousel/10.png"
import mobOne from "../assets/images/howtoplay/carousel/mobile/01.png"
import mobTwo from "../assets/images/howtoplay/carousel/mobile/02.png"
import mobThree from "../assets/images/howtoplay/carousel/mobile/03.png"
import mobFour from "../assets/images/howtoplay/carousel/mobile/04.png"
import mobFive from "../assets/images/howtoplay/carousel/mobile/05.png"
import mobSix from "../assets/images/howtoplay/carousel/mobile/06.png"
import mobSeven from "../assets/images/howtoplay/carousel/mobile/07.png"
import mobEight from "../assets/images/howtoplay/carousel/mobile/08.png"
import mobNine from "../assets/images/howtoplay/carousel/mobile/09.png"
import mobTen from "../assets/images/howtoplay/carousel/mobile/10.png"

const carouselData = [
    {
        img:one,
        mobileImg:mobOne,
        text:'1. Select "+Create" to create a club.'
    },
    {
        img:two,
        mobileImg:mobTwo,
        text:'2. Decide your clubs name, enter it, and click "OK".'
    },
    {
        img:three,
        mobileImg:mobThree,
        text:'3. Your new club will now appear at the top of your clubs list. Clik/tap on the club to access the menu and tables.'
    },
    {
        img:four,
        mobileImg:mobFour,
        text:'4. The next step is to invite people to your club. To do this, share with them your Club ID, which is the 7 digit code below your clubs name.'
    },
    {
        img:five,
        mobileImg:mobFive,
        text:'5. The person you invited must then input your club’s unique 7 digit code after clicking "Join" on SOPO’s homepage and click "OK."'
    },
    {
        img:six,
        mobileImg:mobSix,
        text:'6. Now that you have invited members into your club, the next step is to create a table. Do so by tapping on the table with a plus sign on it as you see above. '
    },
    {
        img:seven,
        mobileImg:mobSeven,
        text:'7. Finally, pick your desired settings for ring games, sit n’ go, or tournaments and click "Create Table." Your new club table will show up in your club lobby momentarily.'
    },
    {
        img:eight,
        mobileImg:mobEight,
        text:'8. If you’d like to explore customization of your club, click on your club logo to open the menu. You can change  the logo, name, and club message!'
    },
    {
        img:nine,
        mobileImg:mobNine,
        text:'9. You can navigate to the "Counter" in Club Controls to manage players’ chip counts and more!'
    },
    {
        img:ten,
        mobileImg:mobTen,
        text:'10. SOPO offers a full range of club reporting, available within "Club Controls" in the "Reports" tab.'
    },
   
]

const Row = ({children}) =>{
    return <div className="row-car">
        {children}
    </div>
}

const Column = ({children}) =>{
    return <div className="col-car">{children}</div>
}

const HomepageCarousel = () =>{
    return <div className="carousel-container gradient-red-bg">
        <h3 className="page-title carousel-title">How To Set Up A Club</h3>
    <Carousel showArrows={true} showThumbs={false} showStatus={false}>
               {carouselData.map((data)=>{
                return <div className="carousel-content">
                <Row>
                    <Column><div><img className="desktop-carousel-img" src={data.img}/><img className="mobile-carousel-img" src={data.mobileImg}/></div></Column>
                    <Column><div className="carousel-text"><div className="wrap"><p>{data.text}</p></div></div></Column>
                </Row>
                </div>
               })}
            </Carousel>
    </div>
}

export default HomepageCarousel;